import { Typography } from '@material-ui/core';
import * as React from 'react';
import { VerifyError } from '../recoil';
import { FailedMessage } from './FailedMessage';
import { Fit } from './Fit';

type State = {
  error?: Error;
};

export class ErrorBoundary extends React.Component {
  state: State = {};

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    const { error } = this.state;

    return !error ? (
      this.props.children
    ) : error instanceof VerifyError ? (
      <FailedMessage type={error.failType} />
    ) : (
      <Fit>
        <Typography variant="h5">{error.message}</Typography>
      </Fit>
    );
  }
}
