const paramas = new URLSearchParams(window.location.search);

/**
 * メールリンクに付随するトークン文字列
 * ブラウザをリロードできるよう sessionStorage で永続化する
 */
export const token = (() => {
  const getParam = paramas.get('token');
  if (getParam) {
    sessionStorage.setItem('token', getParam);
    return getParam;
  }
  return sessionStorage.getItem('token');
})();

/**
 * メールリンクに付随するトークン情報
 */
export const parentalToken = parseToken(token);

/**
 * トークンの認証に失敗した場合のタイプ
 */
export const failType = paramas.get('type') as VerifyFailType | null;
export type VerifyFailType = 'expired' | 'incorrect' | 'invalid' | 'unknown';

/**
 * トークンをパースする
 * @param tokenString GET パラメータで渡されたトークン文字列
 */
function parseToken(tokenString: string | null) {
  if (!tokenString) {
    return null;
  }
  try {
    // base64 to ascii
    const json = atob(tokenString);
    const parsed = JSON.parse(json);
    return parsed as IParentalToken;
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * メールリンクに添付する token の中身
 * 送信時は serialize する
 */
export interface IParentalToken {
  uid: string;
  email: string;
  /**
   * ms 単位の Unix Epoc
   */
  expire: number;
}
