import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { failType, parentalToken } from '../params';
import { ConfirmProvider } from './ConfirmProvider';
import { FailedMessage } from './FailedMessage';
import { Fit } from './Fit';
import { Header } from './Header';
import { NoticeManager } from './NoticeManager';
import { Preference } from './Preference';
import { Verified } from './Verified';

export function App() {
  return (
    <Router>
      <Route path="/" component={Header} />
      <React.Suspense
        fallback={
          <Fit>
            <CircularProgress />
          </Fit>
        }
      >
        <Switch>
          <Redirect
            from="/"
            exact
            to={parentalToken ? '/preference' : '/failed'}
          />
          <Route path="/verified" component={Verified} />
          <Route path="/preference" component={Preference} />
          <Route path="/failed" children={<FailedMessage type={failType} />} />
          <Route children={<FailedMessage type="unknown" />} />
        </Switch>
        <NoticeManager />
        <ConfirmProvider />
      </React.Suspense>
    </Router>
  );
}
