import { selector } from 'recoil';
import { token, VerifyFailType } from '../params';
import { request } from '../utils/request';
import { atomRewind } from './atomRewind';

export interface IParentalControl {
  enablePublish?: boolean;
  enableReceive?: boolean;
  enableSend?: boolean;
}

export class VerifyError extends Error {
  readonly failType: VerifyFailType;
  constructor(failType: VerifyFailType) {
    super('Failed to verify: ' + failType);
    Object.setPrototypeOf(this, VerifyError.prototype);

    this.failType = failType;
  }
}

export const parentalControlState = atomRewind<IParentalControl>({
  key: 'parentalControlState',
  default: async () => {
    if (!token) {
      throw new VerifyError('unknown');
    }
    const result = await request('/api/parentalControls?token=' + token);
    return result.value;
  }
});

export interface IProfile {
  displayName: string;
  iconUrl: string;
}

export const profileState = selector<IProfile>({
  key: 'profileState',
  get: async () => {
    if (!token) {
      throw new VerifyError('unknown');
    }
    const result = await request('/api/parentalControlUsers?token=' + token);
    return result.value;
  }
});
