import { Snackbar } from '@material-ui/core';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import * as React from 'react';
import { atom, useRecoilState } from 'recoil';

/**
 * NoticeManager を使って Snackbar+Alert を表示する
 */
export const noticeAtom = atom<AlertProps | undefined>({
  key: 'noticeAtom',
  default: undefined
});

export function NoticeManager() {
  // 新しい notice が登録されたら alert にセットする
  const [alert, setAlert] = useRecoilState(noticeAtom);

  const handleClose = React.useCallback((event: any, reason?: string) => {
    if (reason === 'clickaway') return; // 画面クリックでは閉じない
    setAlert(undefined);
  }, []);

  return (
    <Snackbar open={Boolean(alert)} onClose={handleClose}>
      <Alert onClose={handleClose} {...alert} />
    </Snackbar>
  );
}
