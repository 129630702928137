import { VerifyError } from '../recoil';

const api =
  process.env.NODE_ENV === 'production'
    ? ''
    : 'https://hackforplay-development-parent.firebaseapp.com';

export async function request<T = any>(
  endpoint: string,
  method = 'GET',
  body?: Object
) {
  const response = await fetch(api + endpoint, {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: body ? JSON.stringify(body) : undefined
  });
  const text = await response.text();
  if (!response.ok) {
    throw new Error(text);
  }
  try {
    const result = JSON.parse(text);
    // { ok: false, type: "..." } というレスポンスは全て VerifyError とみなす
    if (result.ok === false && typeof result.type === 'string') {
      throw new VerifyError(result.type);
    }
    return result as T;
  } catch (error) {
    // パースに失敗した場合は元の文字列を表示する
    console.warn(text);
    throw error;
  }
}
