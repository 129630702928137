import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import * as React from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

interface IMessage {
  message: string;
  yes: string;
  no: string;
  resolve: (answer: boolean) => void;
}

const messagesAtom = atom<IMessage[]>({
  key: 'messagesAtom',
  default: []
});

export function useConfirm() {
  const setMessages = useSetRecoilState(messagesAtom);
  return React.useCallback((message: string, yes: string, no: string) => {
    return new Promise<boolean>(resolve => {
      setMessages(messages =>
        messages.concat({
          message,
          yes,
          no,
          resolve
        })
      );
    });
  }, []);
}

export function ConfirmProvider() {
  const [current] = useRecoilValue(messagesAtom);
  const setMessages = useSetRecoilState(messagesAtom);

  const handleSet = (answer: boolean) => {
    current.resolve(answer);
    setMessages(messages => messages.slice(1));
  };

  return React.useMemo(
    () => (
      <Dialog open={Boolean(current)}>
        <DialogTitle>{current?.message}</DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSet(true)}
          >
            {current?.yes}
          </Button>
          <Button variant="outlined" onClick={() => handleSet(false)}>
            {current?.no}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [current]
  );
}
