import { Typography } from '@material-ui/core';
import * as React from 'react';
import { VerifyFailType } from '../params';
import { Fit } from './Fit';

interface FailedMessageProps {
  type: VerifyFailType | null;
}

export function FailedMessage({ type }: FailedMessageProps) {
  return (
    <Fit>
      <Typography variant="h4">
        {type === 'expired'
          ? 'リンクが期限切れになっています。もう一度メールを送信してください'
          : type === 'incorrect'
          ? 'リンクが無効になっています。もう一度メールを送信してください'
          : type === 'invalid'
          ? '無効なリンクです。もう一度メールを送信してください'
          : '申し訳ありません。もう一度メールを送信してください'}
      </Typography>
    </Fit>
  );
}
