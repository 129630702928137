import { atom, DefaultValue, selector } from 'recoil';

export interface AtomRewindOptions<T> {
  key: string;
  default: () => Promise<T>;
  dangerouslyAllowMutability?: boolean;
}

/**
 * reset() することで options.default を再実行する Atom
 */
export function atomRewind<T>(options: AtomRewindOptions<T>) {
  // reset または set された回数
  const setCount = atom({
    key: `${options.key}__setCount`,
    default: 0
  });

  // set された場合は値を保持
  // reset された場合は reset されたことを保持
  let overrideValue: T | DefaultValue = new DefaultValue();

  return selector<T>({
    key: options.key,
    dangerouslyAllowMutability: options.dangerouslyAllowMutability,
    get: ({ get }) => {
      get(setCount); // set と reset を購読
      return overrideValue instanceof DefaultValue
        ? options.default() // reset されたので関数を再実行
        : overrideValue; // set された値をそのまま返す
    },
    set: ({ set }, newValue) => {
      overrideValue = newValue;
      set(setCount, count => count + 1);
    }
  });
}
