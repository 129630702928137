import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { indigo } from '@material-ui/core/colors';
import { normalize, setupPage } from 'csstips';
import * as React from 'react';
import { render } from 'react-dom';
import { RecoilRoot } from 'recoil';
import 'url-search-params-polyfill';
import { App } from './components/App';
import { ErrorBoundary } from './components/ErrorBoundary';

function Main() {
  const theme = createMuiTheme({
    palette: {
      primary: indigo
    }
  });

  return (
    <ErrorBoundary>
      <RecoilRoot>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </RecoilRoot>
    </ErrorBoundary>
  );
}

setupPage('#app');
normalize();

const app = document.querySelector('#app');
if (!app) {
  throw new Error('#app is not found');
}

render(<Main />, app);
