import {
  Avatar,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import * as React from 'react';
import {
  useRecoilValueLoadable,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil';
import { style } from 'typestyle';
import { useUpdated } from '../hooks/useUpdated';
import { token } from '../params';
import {
  IParentalControl,
  parentalControlState,
  profileState
} from '../recoil';
import { request } from '../utils/request';
import { useConfirm } from './ConfirmProvider';
import { Fit } from './Fit';
import { noticeAtom } from './NoticeManager';

const useStyles = makeStyles(theme => ({
  profile: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    padding: 32,
    paddingTop: 64
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: 16,
    padding: 16,
    maxWidth: 700
  },
  heading: {
    marginBottom: 16
  },
  label: {
    display: 'block',
    marginTop: 16
  }
}));

export function Preference() {
  const cn = useStyles();

  return (
    <div>
      <div className={cn.profile}>
        <Profile />
      </div>
      <Paper elevation={0} className={cn.container}>
        <Typography variant="h5" className={cn.heading}>
          みまもり設定の変更
        </Typography>
        <ToggleItem
          name="enablePublish"
          label="作品の公開を許可する"
          beFalseMessage="この設定を適用すると、作品の公開が出来なくなります。作品の公開が出来ないと、プログラミングを学ぶ意欲を損なう可能性があります。本当に設定を変更しますか？"
          falseMessage="作品の公開ができない設定になっています"
          className={cn.label}
        />
        <ToggleItem
          name="enableReceive"
          label="自分の作品に対するコメントを受け取る"
          falseMessage="コメントを受け取れない設定になっています"
          className={cn.label}
        />
        <ToggleItem
          name="enableSend"
          label="他の人の作品に対してコメントを送れる"
          falseMessage="コメントを送れない設定になっています"
          className={cn.label}
        />
      </Paper>
      <div className={cn.container}>
        <Typography variant="body1" color="textSecondary">
          上記のチェックボックスをクリックすると、みまもり設定を変更できます
        </Typography>
      </div>
    </div>
  );
}

const cn = {
  avatar: style({
    width: 80,
    height: 80,
    marginRight: 16
  }),
  progress: style({
    margin: 9
  }),
  falseMessage: style({
    marginLeft: 32
  })
};

function Profile() {
  const loadable = useRecoilValueLoadable(profileState);

  if (loadable.state === 'hasError') {
    throw loadable.contents;
  }

  return loadable.state === 'loading' ? (
    <Fit>
      <CircularProgress />
    </Fit>
  ) : (
    <>
      <Avatar
        src={loadable.contents.iconUrl}
        alt="icon"
        className={cn.avatar}
      />
      <Typography variant="h6">{loadable.contents.displayName}</Typography>
    </>
  );
}

interface ToggleItemProps {
  name: keyof IParentalControl;
  label: string;
  /**
   * true -> false に変更するときに表示するダイアログ
   */
  beFalseMessage?: string;
  /**
   * false になっているときに下に出す文字
   */
  falseMessage: string;
  className?: string;
}

function ToggleItem(props: ToggleItemProps) {
  // 実際の値 (サーバの値)
  const loadable = useRecoilValueLoadable(parentalControlState);
  const defaultValue =
    loadable.state === 'hasValue' ? loadable.contents[props.name] : undefined;

  // 表示する値
  const [value, setValue] = React.useState(defaultValue);
  useUpdated(() => {
    if (defaultValue !== undefined) {
      setValue(defaultValue); // サーバの値が変化したら同期する
    }
  }, [defaultValue]);

  const setNotice = useSetRecoilState(noticeAtom);
  const rewind = useResetRecoilState(parentalControlState);
  const confirm = useConfirm();
  const toggle = React.useCallback(async () => {
    // 確認のダイアログを表示する
    if (value && props.beFalseMessage) {
      const answer = await confirm(
        props.beFalseMessage,
        'このままにする',
        '不許可にする'
      );
      if (answer) return;
    }

    const nextValue = !value;
    setValue(nextValue);
    try {
      await request('/api/parentalControls', 'POST', {
        token: token,
        key: props.name,
        value: nextValue
      });

      rewind(); // もう一度取得させる
      setNotice({
        severity: 'success',
        children: '設定を変更できました！'
      });
    } catch (error) {
      console.error(error);
      setNotice({
        severity: 'error',
        children:
          '設定を変更できませんでした。申し訳ありませんが、「みまもり設定」のメールをもう一度送信してください。'
      });
    }
  }, [value]);

  if (loadable.state === 'hasError') {
    throw loadable.contents;
  }

  return (
    <>
      <FormControlLabel
        control={
          loadable.state !== 'loading' ? (
            <Checkbox
              checked={Boolean(value)}
              onChange={toggle}
              disabled={value !== defaultValue}
              color="primary"
            />
          ) : (
            <CircularProgress
              color="primary"
              size={24}
              className={cn.progress}
            />
          )
        }
        label={props.label}
        className={props.className}
      />
      {loadable.state === 'loading' || value ? null : (
        <Typography
          variant="body1"
          color="textSecondary"
          className={cn.falseMessage}
        >
          {props.falseMessage}
        </Typography>
      )}
    </>
  );
}
