import * as React from 'react';
import { style } from 'typestyle';

const cn = {
  root: style({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
};

interface FitProps {
  children: React.ReactNode;
}

/**
 * 縦横に目一杯広い Flex container
 */
export function Fit(props: FitProps) {
  return <div className={cn.root}>{props.children}</div>;
}
